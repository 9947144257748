import { faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileUpload } from 'primereact/fileupload';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../../../core/axiosInstance';
import { BackgroundContainer, Gallery } from './Background.style';
import { setLoading } from '../../../features/loading/loadingSlice';
import { useAppDispatch } from '../../../core/hooks';

const Background = () => {
  const formData = new FormData();
  const [images, setimages] = useState([]);
  const [selected, setselected] = useState(-1);
  const [up, setUp] = useState(0);
  const [busy, setbusy] = useState(false);
  let fileUpload: FileUpload | null;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    axiosInstance
      .get('/dashboard/background')
      .then((res: any) => {
        // console.log(res.data.data);
        dispatch(setLoading(false))
        setimages(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false))
        // console.log(err);
      });
  }, [up]);

  const myUploader = (event: any) => {
    formData.append('name', event.files[0].name);
    formData.append('type_id', '1');
    formData.append('content[]', event.files[0]);
    setbusy(true)

    axiosInstance
      .post('/dashboard/background', formData)
      .then((res: any) => {
        // console.log(res.data, event.files);
        setUp(up + 1);
        setbusy(false);
      })
      .catch((err) => {
        // console.log(err);
        setbusy(false);
      });
      fileUpload?.clear();
  };

  const deleteBG = async (id: number, i: number) => {
    dispatch(setLoading(true));
    await axiosInstance
      .delete(`/dashboard/background/${id}`)
      .then((res: any) => {
        let newImages = [...images];
        newImages.splice(i, 1);
        setimages(newImages);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const submitBG = async () => {
    if (selected >= 0) {
      const formUpdate = new FormData();
      formUpdate.append('is_active', '1');
      formUpdate.append('_method', 'put');
      dispatch(setLoading(true));
        await axiosInstance.post(`/dashboard/background/${selected}`, formUpdate)
        .then((res: any) => {
            toast.success('image has been set',{
                position: toast.POSITION.TOP_CENTER,
            })
            dispatch(setLoading(false));
            navigate('/');
        }).catch((err) => {
          dispatch(setLoading(false));
        })
      }
    }
 

  return (
    <BackgroundContainer>
      <h2>HR Department</h2>
      <div className="p-grid">
        <div className="p-col-12 p-lg-6">
          <h4>Upload Background</h4>
          <FileUpload
            name="demo[]"
            customUpload
            uploadHandler={myUploader}
            multiple={false}
            accept =  "image/*"
            maxFileSize = {1000000}
            disabled = {busy}
            ref={(el) => fileUpload = el}
            
          />
        </div>
        <Gallery className="p-col-12 p-lg-6">
          <h4>Select Main Background Form Gallery</h4>
          <button
            onClick={submitBG}
            disabled={!(selected >= 0)}
            title="Choose image first"
          >
            Submit
          </button>
          <div className="imageContainer">
            {images.map((img: any, i) => {
              return (
                <div
                className="imageElement"
                  key={i}
                  onClick={() => {
                    setselected(img.id);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="favIcon delete"
                    onClick={() => {
                      deleteBG(img.id, i);
                    }}
                  />
                  <img src={img.url} alt={img.name} />
                  {(selected === img.id || (img.active === '1' && selected === -1)) && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="favIcon check"
                    />
                  )}
                </div>
              );
            })}
          </div>
        </Gallery>
      </div>
    </BackgroundContainer>
  );
}

export default Background;
