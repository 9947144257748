import styled from 'styled-components';
import { slideFromLeft } from '../Main/Main.style';

export const LogHistorydiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const MainTitle = styled.h2`
  font-size: 1.6rem;
  font-weight: 500;
  margin: 1rem 0px;
  animation: ${slideFromLeft} 1s ease-in-out;
`;

export const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Content = styled.div`
  position: relative;
  margin-right: 1rem;

  /* left: 12rem;
  top: 3rem; */
`;

export const DataTitle = styled.p`
  font-size: 2rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 0;
`;

export const Separator = styled.p`
  height: 1px;
  width: 100%;
  margin: 1rem 0px;
`;

export const Paginatior = styled.div`
  margin: 0px 0px 0px 50%;
  transform: translate(-50%, -10px);
  width: 20rem;
  button {
    color: #fff;
    padding: 8px 16px;
    margin: 0 5px;
    text-decoration: none;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--primary-color);;
    display: inline-block;
  }
  p {
    display: inline-block;
    border-radius: 50%;
    background-color: var(--primary-color);;
    color: #fff;
    padding: 6px 10px;
  }
`;
