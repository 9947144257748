import styled from 'styled-components';

export const BackgroundContainer = styled.div`
  .p-col-6 {
    .p-fileupload .p-fileupload-content {
      height: 55vh;
      overflow: hidden;
      overflow-y: scroll;
    }
  }
  .p-button,
  .p-button:enabled:hover,
  .p-button:not(button):not(a):not(.p-disabled):hover {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  h2 {
    font-weight: 400;
  }
`;

export const Gallery = styled.div`
  position: relative;
  .imageElement {
    width: 19vh;
    height: 11vh;
    margin: 1vh;
    position: relative;
    display: inline-flex;
    img {
      width: 100%;
      height: 100%;
    }
    .delete {
      position: absolute;
      top: 5px;
      right: 5px;
      color: red;
      cursor: pointer;
    }
    .check {
      position: absolute;
      top: 2vh;
      left: 6vh;
      font-size: 7vh;
      color: #009900;
      cursor: pointer;
    }
  }
  .imageContainer {
    display: flex;
    flex-wrap: wrap;

    overflow: hidden;
    overflow-y: scroll;
  }
  button {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 0.5rem 1rem;
    color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    :disabled {
      background-color: #c7bba6cc;
    }
  }
`;
