import {
  AddAnnounce, Label, BodyContent, ButtonGroup, GoldenButton,
  GoldenButtonBorder, HeaderContent, MainTitle,
} from './CreateAnnounce.style';
import { useAppDispatch, useAppSelector } from '../../../core/hooks';
import axiosInstance from '../../../core/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useEffect, useRef, useState } from 'react';
import Image from '../../../components/AnnouncementComponents/Image';
import Video from '../../../components/AnnouncementComponents/Video';
import Text from '../../../components/AnnouncementComponents/Text';
import { classNames } from 'primereact/utils';
import moment from 'moment';
import { openPage, selectPerview, } from '../../../features/previewedPage/previewPageSlice';
import { toast } from 'react-toastify';
import Spinner from '../../../components/Spinner/Spinner';

const CreateAnnounce = () => {
  const dispatch = useAppDispatch();
  const page = useAppSelector(selectPerview);
  const [startdate, setstartdate] = useState(new Date());
  const [invalidDates, setInvalidDates] = useState<Date[]>([])
  const [title, setTitle] = useState('');
  const [bodyParagraph, setBodyParagraph] = useState('');
  const [bodytemplate, setbodytemplate] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [images, setImages] = useState([]);
  const [imgIds, setImgIds] = useState([]);
  const [method, setMethod] = useState<string>('add');
  const [files, setFiles] = useState<any>([]);
  const [type_id, settype] = useState(0);
  const [endDate, setendDate] = useState(new Date());
  const submitBtn: any = useRef(null);
  const navigate = useNavigate();
  let formData = new FormData();
  let status = 0;
  const [loading, setLoading] = useState(false)

  const defaultValues: any = {
    name: page.page.name,
    type_id: page.page.type,
    start_at: page.page.start_at ? moment( page.page['start_at']).add(-2,'h').toDate() : '',
    expiry_at: page.page.expiry_at ? moment( page.page['expiry_at']).add(-2,'h').toDate() : '',
  };

  // const getDaysArray = function (start: Date, end: Date) {
  //   let arr:Date[] = []
  //   const startDay = moment(start)
  //   const endDay = moment(end)
  //   const days = endDay.diff(startDay,'d')
  //   console.log('days',days)
  //   for (let index = 1; index < days + 1 ; index++) {
  //     const day = startDay.add(index,'d')
  //     console.log('day',day.toDate())
  //     arr.push(day.toDate())
  //     console.log('arr------>',arr)
  //   }
  //   return arr;
  // };

  const getDaysArray = function (start: Date, end: Date) {
    let arr:Date[] = []
    let pageStart = moment()
    let pageEnd = moment()
    if(page.page.id){
      console.log('edit')
     pageStart =  moment( page.page['start_at']);
     pageEnd =  moment( page.page['expiry_at']);
    }

    const startDay = moment(start)
    let xdate = startDay.clone();
    // const endDay = moment(end)
    let days =  end.valueOf() - start.valueOf()  // endDay.diff(startDay,'d')
    days =Math.ceil((days /1000 /60/ 60) / 24)
    // console.log('days' , days)
    for (let index = 1; index <= days + 1 ; index++) {
      if(page.page.id && (xdate.isSame(pageStart) || xdate.isSame(pageEnd))){
        continue
      }else{
        arr.push(xdate.toDate())
        xdate.add(1,'day')
        // const day = startDay.add(index,'days')
        // console.log('day',xdate.format('YYYY MM DD'))
        // console.log('arr------>',arr)

      }
    }
    return arr;
  };
  const getEvents = async () => {
    let arrDates: any[] = [];
    const calenderEvents: any = await axiosInstance.get('dashboard/pages/published/dates');
    // console.log('event ****', calenderEvents.data)
    for (const event of calenderEvents.data) {
      const start = new Date(event.start_at)
      const end = new Date(event.expiry_at)
      let internalArr:any =[]
      // if(moment(start) !== moment( page.page['start_at']).add(-2,'h')){
        //todo: fix it
        if(method === 'add'){
          internalArr = getDaysArray(start, end)
        }
        // console.log("dates", start, end, internalArr)
       // internalArr++;
      // }
      // console.log('inter',internalArr)
      arrDates = [...arrDates, ...internalArr]
    }
    // console.log('arr dates', arrDates)
    setInvalidDates(arrDates)
  }

 
  useEffect(() => {
    if (page.page.id) {
      // console.log(page);
      // settype(page.page.status);
      setMethod('edit');
      for (const key in defaultValues) {
        if(key === 'start_at' || key === 'expiry_at') {
          const date = moment( page.page[key]).add(-2,'h').toDate()
          console.log('date',date)
          defaultValues[key] = date
          console.log(defaultValues)
        }else{
          defaultValues[key] = page.page[key];
        }
      }
      if (page.page.text) {
        settype(3);
        setTitle(page.page.text.title);
        setBodyParagraph(page.page.text.body);
        setbodytemplate(page.page.text.template.id);
      }
      if (page.page.media.length > 0 && page.page.media[0].type_id === 2) {
        setVideoLink(page.page.media[0].url);
        settype(2);
      }
      if (page.page.media.length > 0 && page.page.media[0].type_id === 1) {
        setImages(page.page.media);
        const ids = page.page.media.map((id: any) => {
          // console.log(id);
          return id.id;
        });
        setImgIds(ids);
        // console.log(ids);
        settype(1);
      }
    }
    getEvents()
    return () => {
      dispatch(openPage({ page: {} }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  const TypeSelection = [
    { label: 'images', value: 1 },
    { label: 'video', value: 2 },
    { label: 'text', value: 3 },
  ];

  let today = new Date();
  let minDate = new Date();
  minDate.setTime(today.getTime());
  minDate.setDate(today.getDate());
  minDate.setMonth(today.getMonth());
  minDate.setFullYear(today.getFullYear());

  let maxDate = new Date();
  maxDate.setMonth(today.getMonth() + 1);
  maxDate.setFullYear(today.getFullYear() + 1);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      // console.log(data);
      let requestData: any;
      switch (type_id) {
        case 1:
          for (const key in data) {
            const element = data[key];
            if (key === 'start_at' || key === 'expiry_at') {
              console.log('ele',element)
              let newEle: any = moment(element).format('MMM DD YYYY HH:mm:ss ');
              // console.log('newEle', newEle.toLowerCase());
              let newDate = newEle.toLowerCase();
              newDate += ' GMT+0200';
              formData.append(key, newDate);
            } else {
              formData.append(key, element);
            }
          }
          if (files.length <= 0 && imgIds.length <= 0) {
            setLoading(false);
            toast.error('you must select at least 1 image', {
              position: toast.POSITION.TOP_CENTER,
            })
            formData = new FormData()
            return;
          }
          for (const file of files) {
            formData.append(`content[]`, file);
          }
          formData.append('status', `${status}`);
          requestData = formData;
          break;
        case 2:
          requestData = { ...data };
          requestData.status = status;
          const startVideo = moment(requestData.start_at).add(2,'h')
          const endVideo = moment(requestData.expiry_at).add(2,'h')
          requestData.start_at = startVideo.toDate()
          requestData.expiry_at =endVideo.toDate()
          if (videoLink.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)) requestData.content = videoLink;
          else {
            toast.error('the video must be Link', {
              position: toast.POSITION.TOP_CENTER,
            })
            setLoading(false);
            return;
          }

          break;
        case 3:
          requestData = { ...data };
          requestData.status = status;
          requestData.title = title;
          
          const startText = moment(requestData.start_at)
          const endText = moment(requestData.expiry_at)
          requestData.start_at = startText.toDate()
          requestData.expiry_at =endText.toDate()
          if (!bodyParagraph || bodyParagraph.length <= 0) {
            setLoading(false);
            toast.error('body can\'t be empty', {
              position: toast.POSITION.TOP_CENTER
            })
            return
          }
          requestData.body = bodyParagraph;
          if (!bodytemplate || bodytemplate.length <= 0) {
            setLoading(false);
            toast.error('you must select template', {
              position: toast.POSITION.TOP_CENTER
            })
            return
          }
          requestData.template_id = bodytemplate;
          // console.log('req', requestData);
          break;
        default:
          break;
      }
      const perfix = method === 'add' ? '/dashboard/pages' : `/dashboard/pages/${page.page.id}`;
      if (method === 'edit' && type_id === 1) {
        requestData.append('_method', 'put');
        for (const id of imgIds) {
          requestData.append('images_ids[]', `${id}`);
        }
      }
      if (method === 'edit' && type_id !== 1) requestData._method = 'put';

      console.log(perfix, requestData)
      const res = await axiosInstance.post<any>(perfix, requestData);
      console.log('res', res)
      setLoading(false);
      toast.success('Your announcement has been set successfully', {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate('/');
      // console.log('success');
    } catch (err: any) {
      setLoading(false);
      console.log('we have error', err)
      formData = new FormData();
    }
  };

  const getFormErrorMessage = (name: string) => {
    return (
      errors[`${name}`] && (
        <small className="p-error">
          {errors ? errors[`${name}`]?.message : null}
        </small>
      )
    );
  };

  return (
    <AddAnnounce>
      <HeaderContent>
        <MainTitle title="HR Department">HR Department</MainTitle>
        <ButtonGroup>
          <GoldenButton
            onClick={() => {
              status = 1;
              submitBtn.current.click();
            }}
            type="submit"
          >
            publish
          </GoldenButton>
          <GoldenButtonBorder
            onClick={() => {
              status = 0;
              submitBtn.current.click();
            }}
          >
            save without puplish
          </GoldenButtonBorder>
        </ButtonGroup>
      </HeaderContent>

      <BodyContent>
        <h3>New Announcement</h3>
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <button ref={submitBtn} type="submit" hidden></button>
          <div className="p-grid">
            <div className="p-col-12 p-md-6 p-lg-3">
              <Label
                className={classNames({
                  'p-error': errors[`name`],
                })}
                htmlFor="name"
              >
                Announcement Name
              </Label>
              <Controller
                name="name"
                control={control}
                rules={{ required: 'Name is required.' }}
                render={({ field, fieldState }) => (
                  <InputText
                    type="text"
                    id={field.name}
                    {...field}
                    placeholder="Enter name"
                    // autoFocus

                    className={classNames({
                      'p-invalid': fieldState.invalid,
                      'p-mt-2': true,
                    })}
                  />
                )}
              />
              {getFormErrorMessage('name')}
            </div>
            <div className="p-col-12 p-md-6 p-lg-3">
              <Label
                className={classNames({
                  'p-error': errors[`type_id`],
                })}
                htmlFor="type_id"
              >
                Announcement Type
              </Label>
              <Controller
                name="type_id"
                control={control}
                rules={{ required: 'type is required.' }}
                render={({ field, fieldState }) => (
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => {
                      settype(e.value);
                      field.onChange(e.value);
                    }}
                    options={TypeSelection}
                    placeholder="Enter type"
                    autoFocus
                    className={classNames({
                      'p-invalid': fieldState.invalid,
                      'p-mt-2': true,
                    })}
                  />
                )}
              />
              {getFormErrorMessage('type_id')}
            </div>
            <div className="p-col-12 p-md-6 p-lg-3">
              <Label
                className={classNames({
                  'p-error': errors[`start_at`],
                })}
                htmlFor="start_at"
              >
                {' '}
                Start date
              </Label>
              <Controller
                name="start_at"
                control={control}
                rules={{ required: 'Start date is required.' }}
                render={({ field, fieldState }) => (
                  <Calendar
                    id={field.name}
                    value={field.value}
                    onChange={(e: any) => {
                      setstartdate(e.value);
                      field.onChange(e.value);
                    }}
                    showIcon
                    showTime
                    hourFormat="24"
                    className={classNames({
                      'p-invalid': fieldState.invalid,
                      'p-mt-2': true,
                    })}
                    disabledDates={invalidDates}
                    minDate={minDate}
                    maxDate={maxDate}
                    tooltip="The invisible date and time are already blocked. Consider rescheduling."
                  />
                )}
              />
              {getFormErrorMessage('start_at')}
            </div>
            <div className="p-col-12 p-md-6 p-lg-3">
              <Label
                className={classNames({
                  'p-error': errors[`expiry_at`],
                })}
                htmlFor="expiry_at"
              >
                {' '}
                End Date
              </Label>
              <Controller
                name="expiry_at" control={control} rules={{ required: 'End date is required.' }}
                render={({ field, fieldState }) => (
                  <Calendar id={field.name} className={classNames({ 'p-invalid': fieldState.invalid, 'p-mt-2': true, })}
                    value={field.value} showIcon showTime hourFormat="24" minDate={startdate} maxDate={maxDate}
                    onChange={(e: any) => {
                      setendDate(e.value);
                      field.onChange(e.value);
                    }}
                    disabledDates={invalidDates}
                    tooltip="The invisible date and time are already blocked. Consider rescheduling."
                  />
                )}
              />
              {getFormErrorMessage('expiry_at')}
            </div>
          </div>
          <div className="p-grid">
            {type_id === 1 ? (
              <Image setFiles={setFiles} files={files} setimageids={setImgIds} imgIds={imgIds} />
            ) : type_id === 2 ? (
              <Video setVideoLink={setVideoLink} videoLink={videoLink} />
            ) : type_id === 3 ? (
              <Text setTitle={setTitle} setBodyParagraph={setBodyParagraph} setbodytemplate={setbodytemplate}
                title={title} bodyParagraph={bodyParagraph} bodytemplate={bodytemplate} />
            ) : (
              <div></div>
            )}
          </div>
        </form>
        <span style={{fontSize:'.8vw', position:'absolute', color:'#d72525', bottom:'1vh', left:"2vw" }}>* The invisible date and time are already blocked. Consider rescheduling.</span>
      </BodyContent>
      
      {loading ? <Spinner /> : null}
    </AddAnnounce>
  );
};

export default CreateAnnounce;
