// import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useForm, Controller } from 'react-hook-form';
import { FomrDiv, Label } from '../../layout/Login/Login.style';
import { useNavigate } from 'react-router-dom';
import './LoginForm.css';
import { useAppDispatch, useAppSelector } from '../../core/hooks';
import { signIn } from '../../features/auth/authSlice';
import { ProgressSpinner } from 'primereact/progressspinner';

import { UserCredentials } from '../../features/auth/UserCredentials.interface';
import { LoginResponse } from '../../features/auth/LoginResponse.interface';
import { login } from '../../features/auth/auth.api';
import { loadingSelector, setLoading } from '../../features/loading/loadingSlice';
import { classNames } from 'primereact/utils';
import { toast } from 'react-toastify';

const LoginForm = () => {
  // initial value for login form
  const defaultValues: any = {
    email: 'admin@admin.com',
    password: '123456',
  };

  //navigator to navigate to home if login sucess
  const navigate = useNavigate();

  //store actions to store creadentials to the store
  const dispatch = useAppDispatch();

  //selectors
  const isLoading = useAppSelector(loadingSelector);

  //hook form to genrate form data
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  //form on submit
  /**
   * @todo
   * make axios call
   * send the data to store from dispatch
   * send the token to localStorage
   * navigate to my home if signIn successful
   * @param
   * email && password
   */
  const onSubmit = async (data: UserCredentials) => {
    dispatch(setLoading(true));
    await login(data)
      .then((res: LoginResponse) => {
        dispatch(signIn(res.token.access_token));
        localStorage.setItem('userToken', res.token.access_token);
        // dispatch(setLoading(false));
        // setLoading(false);
        navigate('/');
      })
      .catch((err: any) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        // console.log('err', err.response.data.message);
        dispatch(setLoading(false));
      });
  };

  const getFormErrorMessage = (name: string) => {
    return (
      errors[`${name}`] && (
        <small className="p-error">
          {errors ? errors[`${name}`]?.message : null}
        </small>
      )
    );
  };

  return (
    <FomrDiv>
      <div className="p-d-flex p-jc-center" style={{ position: 'relative' }}>
        <div className="card">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <div className="p-field">
              <Label
                className={classNames({
                  'p-error': errors[`email`],
                })}
                htmlFor="email"
              >
                Email
              </Label>
              <Controller
                name="email"
                control={control}
                rules={{ required: 'Email is required.' }}
                render={({ field, fieldState }) => (
                  <InputText
                    type="email"
                    disabled={isLoading ? true : false}
                    id={field.name}
                    {...field}
                    className={classNames({
                      'p-invalid': fieldState.invalid,
                    })}
                    placeholder="Enter your email"
                  />
                )}
              />
              {getFormErrorMessage('email')}
            </div>
            <div className="p-field">
              <Label
                className={classNames({
                  'p-error': errors[`password`],
                })}
                htmlFor="password"
              >
                Password
              </Label>
              <Controller
                name="password"
                control={control}
                rules={{ required: 'Password is required.', minLength: {value:6 , message: 'At least 6 digits'} }}
                render={({ field, fieldState }) => (
                  <Password
                    disabled={isLoading ? true : false}
                    id={field.name}
                    {...field}
                    className={classNames({
                      'p-invalid': fieldState.invalid,
                    })}
                    placeholder="Enter Your Password"
                    toggleMask
                  />
                )}
              />
              {getFormErrorMessage('password')}
            </div>

            {/* <FlexBox>
              <div className="p-field-checkbox">
              <Checkbox
                disabled={isLoading ? true : false}
                onChange={(e) => setRememberMe(e.checked)}
                checked={rememberMe}
              />
              <Label htmlFor="rememberMe"> Remember Me</Label>
            </div>
            <ForgotPassword onClick={()=>navigate('/forgotPassword')}>
              Forgot Password?
            </ForgotPassword>
            </FlexBox> */}

            <Button
              type="submit"
              label="Login"
              className="p-mt-2 submit-btn"
              disabled={isLoading ? true : false}
            >
              {isLoading ? (
                <ProgressSpinner style={{ height: '1.4rem', textAlign: 'center' }} />
              ) : null}
            </Button>
          </form>
        </div>
      </div>
      {/* <Mask /> */}
    </FomrDiv>
  );
};

export default LoginForm;
