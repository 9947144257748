import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import axiosInstance from '../../../core/axiosInstance';
import moment from 'moment';
import { Warper } from './Calender.style';
import { useAppDispatch } from '../../../core/hooks';
import { setLoading } from '../../../features/loading/loadingSlice';

const Calender = () => {
  const [events, setEvents] = useState([]);
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setLoading(false))
    
  }, [])
  const getStatus: any = (obj: any) => {
    const daysBeforstart = new Date(obj.start_at);
    const startAt = moment(daysBeforstart);
    const daysBeforEnd = new Date(obj.expiry_at);
    const endAt = moment(daysBeforEnd);
    
    

    if (obj.status === 1) {
      if ( moment().isBetween(startAt,endAt) ) return 'green'
      if (startAt.isAfter(moment())) return '#0d89ec'
      if (endAt.isBefore(moment())) return 'red';
    }else{
      if(endAt.isBefore(moment())) return 'red'
      else {
        // console.log(moment() , endAt ,obj.name)
        return 'orange'
      } 
    }
  };

  const getEvents = async () => {
    const calenderEvents: any = await axiosInstance.get('dashboard/calendar');
    // console.log(calenderEvents);
    const mapedEvents = calenderEvents.data.data.map((event: any) => {
      const color = getStatus(event);
      return {
        title: event.name,
        start: event.start_at,
        end: event.expiry_at,
        color,
      };
    });
    setEvents(mapedEvents);
  };
  useEffect(() => {
    getEvents();
  }, []);
  return (
    <Warper>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={events}
      />
    </Warper>
  );
};

export default Calender;
