import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';

const Filter = (props: any) => {
  const [selectedStatus, setselectedStatus] = useState<any>({});
  const [startdate, setstartdate] = useState();
  const [name, setName] = useState<string>('');
  const statues = [
    { name: 'Published', id: '3' },
    { name: 'Draft', id: '1' },
    { name: 'Pending', id: '2' },
    { name: 'Expired', id: '4' },
    { name: 'Deleted', id: '5' },
  ];

  const submitBtn = () => {
    let queryString = `?`;

    if (startdate) {
      const date = moment(startdate);
      const formateDate = date.format('YYYY-MM-DD');
      // console.log('formateData', formateDate);
      queryString += `start_at=${formateDate}&`;
    }
    if (name.length > 0) {
      queryString += `name=${name}&`;
    }
    if (selectedStatus && selectedStatus.id && selectedStatus.id.length > 0) {
      // console.log(selectedStatus);
      queryString += `status=${selectedStatus.id}&`;
    }

    // date controler

    //to remove the & if the user create query or ? if the user don't choise any thing
    queryString = queryString.substring(0, queryString.length - 1);
    // console.log('query', queryString);

    props.setQuery(queryString);
  };

  return (
    <div className="p-formgroup flex-inline">
      <div className="p-filed flex-inline">
        <label htmlFor="status" className="p-sr-only">
          Status
        </label>
        <InputText
          placeholder="name"
          type="text"
          onChange={(e) => setName(e.target.value)}
        />
        <Dropdown
          value={selectedStatus}
          options={statues}
          onChange={(e: any) => setselectedStatus(e.value)}
          optionLabel="name"
          filter
          showClear
          filterBy="name"
          placeholder="Page Status"
        />
        <Calendar
          style={{ marginLeft: '1rem' }}
          id="from"
          onChange={(e: any) => {
            setstartdate(e.value);
          }}
          showIcon
          placeholder="Start at"
          showTime
          hourFormat="24"
        />
      </div>
      <Button
        type="button"
        className="golden-btn filter-btn"
        label="Submit"
        onClick={submitBtn}
      />
    </div>
  );
};

export default Filter;
