import React from 'react';
import { InputText } from 'primereact/inputtext';
import { AnnounceComponent } from './Component';

const Video = (props: any) => {
  return (
    <AnnounceComponent>
      <InputText
        required
        type="text"
        placeholder="Video link here"
        autoFocus
        className="p-m-2"
        value={props.videoLink ? props.videoLink : ''}
        onChange={(e) => props.setVideoLink(e.target.value)}
      />
    </AnnounceComponent>
  );
};

export default Video;
