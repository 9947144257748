import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../core/axiosInstance';
import { useAppDispatch, useAppSelector } from '../../core/hooks';
import {
  openPage,
  selectPerview,
} from '../../features/previewedPage/previewPageSlice';
import { MediaContainer, PreviewContainer, Slider, TextContainer, ImgBC } from './Preview.Style';
import ImageGallery from 'react-image-gallery';
// import './perview.css'

const Preview = () => {
  const page = useAppSelector(selectPerview);
  const dispatch = useAppDispatch();
  // console.log(page);
  const [selectedPage, setselectedPage] = useState<any>({});
  const { id } = useParams();

  const images = selectedPage.media?.map((image: any, i: number) => {
    return (
      {
        original: image.url,
        thumbnail: image.url
      }
    )
  });

  const getVideolink = () => {
    let link = selectedPage?.media[0].url;
    let domain = link.split('.com')[0].split('//')[1];
    if (domain === 'vimeo') {
      var vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
      var parsed = link.match(vimeoRegex);
      link = "https://player.vimeo.com/video/" + parsed[1];
    }
    else {
      var videoId = link.split("?v=")[1]?.split('&')[0]; //id
      if (videoId) {
        link = "https://www.youtube.com/embed/" + videoId;
      }

    }
    // console.log(link);
    return link;
  }

  useEffect(() => {
    if (id) {
      let subUrl =
        (typeof (parseInt(id)) === 'number') && Math.round(parseInt(id)) === parseInt(id)
          ? '/dashboard/pages/' : '/pages/';
      axiosInstance
        .get(`${subUrl}${id}`)
        .then((res: any) => {
          setselectedPage(res.data.data);
          // console.log('RoutedPage', res.data.data);
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      setselectedPage(page.page);
    }
    return () => {
      dispatch(openPage({ page: {} }));
    };
  }, []);

  return (
    (selectedPage.media?.length > 0) && (selectedPage.media[0]?.type_id === 1) && (selectedPage.media?.length > 0) ?
      <Slider>
        <ImgBC src={selectedPage.background} alt="" />
        <div className="overlay" />
        <ImageGallery items={images} />
      </Slider>
      :
      <PreviewContainer>
        <img src={selectedPage.background} alt="" />
        <div className="overlay">
          {selectedPage?.text && (
            <TextContainer>
              {
                selectedPage.text.template.path &&
                <img src={selectedPage.text.template.path} alt="" />
              }
              <div
                className="header"
                dangerouslySetInnerHTML={{ __html: selectedPage.text?.title }}
              ></div>
              <div className="t-body"
                dangerouslySetInnerHTML={{ __html: selectedPage.text?.body }}
              ></div>
            </TextContainer>
          )}

          {((selectedPage.media?.length > 0) && (selectedPage.media[0]?.type_id === 2)) &&
            (
              <MediaContainer>
                <iframe
                  title="video_frame"
                  src={getVideolink()}
                  height="100%" width="100%"
                />
              </MediaContainer>
            )
            // getVideolink()
          }

          {((selectedPage.media?.length > 0) && (selectedPage.media[0]?.type_id === 1) && (selectedPage.media?.length === 1)) && (
            <MediaContainer>
              <img src={selectedPage.media[0]?.url} alt={selectedPage.media[0]?.name} width="100%"></img>
            </MediaContainer>
          )}
        </div>
      </PreviewContainer>
  );
};

export default Preview;
