import React, { useEffect, useState } from 'react';
import { LogHistorydiv, MainTitle, Paginatior } from './LogHistory.style';
import axiosInstance from '../../../core/axiosInstance';
import Card from '../../../components/Card/Card';
import { useAppDispatch } from '../../../core/hooks';
import { setLoading } from '../../../features/loading/loadingSlice';

const LogHistory = () => {
    const [actions, setactions] = useState([]);
    const [totalRecode, setTotalRecode] = useState<number>(0);
    const [nextLink, setNextLink] = useState<string>('');
    const [prevLink, setPrevLink] = useState<string>('');
    const [firstLink, setFirstLink] = useState<string>('');
    const [lastLink, setLastLink] = useState<string>('');
    const [currentPage, setcurrentPage] = useState('');
    const dispatch = useAppDispatch();
    
    const [perfix, setPerfix] = useState<string>('/dashboard/action-logs');

    const cols = [
        {
            field: 'name', Name: 'Name', sortable: false, filter: false, filterPlaceholder: '', body: false,
        },
        {
            field: 'action', Name: 'Action', sortable: false, filter: false, filterPlaceholder: '', body: false,
        },
        {
            field: 'status', Name: 'Status', sortable: false, filter: false, filterPlaceholder: '', body: false,
        },
        {
            field: 'date', Name: 'Date', sortable: false, filter: false, filterPlaceholder: '', body: false,
        },
    ];

    const getStatus: any = (obj: any) => {
        const timeBeforstart = new Date(`${obj.page.start_at}`).getTime() - new Date().getTime();
        const timeBeforEnd = new Date(`${obj.page.expiry_at}`).getTime() - new Date().getTime();

        if (obj.page.status === 1) {
            if (timeBeforstart > 0) {
                return 'Pending';
            } else if (timeBeforstart < 0 && timeBeforEnd > 0) {
                return 'Published';
            } else {
                //start < 0 && end < 0
                return 'Expired';
            }
        }
        //status != 1
        else {
            if (timeBeforEnd > 0) {
                return 'draft';
            } else if (timeBeforEnd < 0) {
                return 'Expired';
            }
        }
    };

    const data: any = [];

    actions.forEach((action: any, index: number) => {
        data.push({
            id: action.id, name: action.page.name, action: action.action,
            status: getStatus(action), date: action.date.split('.')[0].split('T')[0],
        });
    });
    const getData = async()=>{
        // 
        try {
            const res:any = await axiosInstance.get(perfix)
            setactions(res.data.data);
                setTotalRecode(res.data.meta.total);
                // console.log(res.data.data);
                setNextLink(res.data.links.next?.split('?')[1]);
                setPrevLink(res.data.links.prev?.split('?')[1]);
                setFirstLink(res.data.links.first?.split('?')[1]);
                setLastLink(res.data.links.last?.split('?')[1]);
                setcurrentPage(res.data.meta?.current_page);
                dispatch(setLoading(false));
        } catch (error) {
            dispatch(setLoading(false));
        }
    }
    
    useEffect(() => {
        getData()
    }, [perfix]);

    return (
        <LogHistorydiv>
            <div>
                <MainTitle title="HR Department">HR Department</MainTitle>
            </div>
            <Card
                hasFilter={false}
                title="History"
                cols={cols}
                data={data}
                actions={['delete']}
                totalRecode={totalRecode}
                setactions={setactions}
            />
            <Paginatior>
                <button onClick={()=>{setPerfix(`/dashboard/action-logs?${firstLink}`)}}>&laquo;</button>
                <button onClick={()=>{setPerfix(`/dashboard/action-logs?${prevLink}`)}}>&lt; Prev</button>
                <p>
                    {currentPage}
                </p>
                <button onClick={()=>{setPerfix(`/dashboard/action-logs?${nextLink}`)}}>Next &gt;</button>
                <button onClick={()=>{setPerfix(`/dashboard/action-logs?${lastLink}`)}}>&raquo;</button>
            </Paginatior>
        </LogHistorydiv>
    );
};

export default LogHistory;
