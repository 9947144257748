import styled, { keyframes } from 'styled-components';
const enterFromAbove = keyframes`
  0%{
    transform:translate(0,-1rem);
    opacity:0;
  }
  100%{
    transform:translate(0px,0px);
    opacity:1;
    
  }
`;

export const AnnounceComponent = styled.div`
  width: 100%;
  animation: 1.5s ${enterFromAbove} ease-in-out;

  .editor {
  }

  .p-divider {
    margin: 0.4rem 0px !important;
  }
`;

export const ImageContainer = styled.div`
  /* border: solid 1px #e7e7e7; */
  /* border-radius: 5px; */
  height: 53vh;
  position: relative;

  > h3 {
    font-weight: 400;
    margin: 0.4rem 0px 0px;
  }

  > .p-divider {
    margin: 0.4rem 0px !important;
  }

  .main_image {
    position: absolute;
    z-index: 10;
    top: 50px;
    left: 9px;
    width: 72vh;
    max-width: 98%;
    height: 41vh;
  }

  .favIcon {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 100;
    color: #ff5656;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }

  button {
    color: #fff;
    text-transform: capitalize;
    font-weight: bold;
    width: 15%;
    height: 7%;
    cursor: pointer;
    background-color: var(--primary-color);;
    border: 0;
    border-radius: 25px;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

export const DroppableArea = styled.div`
  position: absolute;
  top: 50px;
  left: 9px;
  width: 80%;
  height: 40vh;
  border: 1px #d1d1d1 dashed;
`;

export const UploadedFilesTitle = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin: 0.4rem 0px 0.9rem;
`;

export const UploadedFilesImage = styled.img`
  margin: 2px;
  width: 80px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;

  > h3 {
    font-weight: 400;
    margin: 0.4rem 0px 0px;
  }
`;

export const UploadedFilesContainer = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  height: 36.5vh;
`;

export const EntrenceWarper = styled.div``;

// Gallery
// export const GalleryContainer = styled.div`
//   margin: 1rem 0px 0px 1rem;
//   border: solid 1px #e7e7e7;
//   border-radius: 5px;
//   width: calc(25% - 1rem);
//   height: 53vh;
//   overflow: hidden;
//   overflow-y: scroll;
//   > h3 {
//     font-weight: 400;
//     margin: .3rem 0px 0px;
//     };
//     img {
//       width: 16vw;
//       height: 9vw;
//       margin: 2px;
//     };
//     .p-divider {
//       margin: .4rem 0px !important;
//     };
// `;
